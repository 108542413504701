import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState("desktop"); // Set an initial value

  useEffect(() => {
    // Function to get screen size
    const getScreenSize = () => {
      if (typeof window === "undefined") return;
      else if (window.innerWidth <= 1024) {
        return "mobile";
      } else {
        return "desktop";
      }
    };

    // Set the initial screen size
    setScreenSize(getScreenSize());

    // Event listener for window resize
    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs only once when the component mounts

  return [screenSize, setScreenSize];
};

export default useScreenSize;
