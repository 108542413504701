import { DotLottiePlayer, PlayerEvents } from "@dotlottie/react-player";
import styles from "./MeetTheTeam.module.css";
import { useEffect, useRef, useState } from "react";
import { useKey } from "./useKey";
import { Link } from "react-router-dom";
import MTTLoadingScreen from "./MTTLoadingScreen";

const mttDetails = {
  debanjana: {
    fullName: "Debanjana Saha",
    designation: "Lead Illustrator",
    description:
      "Our creative cat mom! She paints with love and two furry paws.",
  },
  sayok: {
    fullName: "Sayok Das",
    designation: "Illustrator",
    description: "The most talented guy we know!",
  },
  debidas: {
    fullName: "Debidas Ghosh",
    designation: "Lead Animator",
    description: "HARD WORK!... HARD WORK!...",
  },
  parijat: {
    fullName: "Parijat Mukherjee",
    designation: "Director",
    description: "Loves all things tech! And he's a poet too!",
  },
  souradip: {
    fullName: "Souradip Mondal",
    designation: "Animator",
    description: "Makes excellent Instagram reels!",
  },
  arka: {
    fullName: "Arka Bhowmik",
    designation: "CTO",
    description: "Can I get a Vision Pro, please?",
  },
  suchorita: {
    fullName: "Suchorita Chakroborty",
    designation: "Animator",
    description: "Part time animator. Part time dancer. Full time artist!",
  },
  subhajit: {
    fullName: "Subhajit Das",
    designation: "Animator",
    description: "Loves gaming. Loves cats. Loves animation.",
  },
  purnima: {
    fullName: "Purnima Ghosh",
    designation: "Illustrator",
    description: "Anime fan. 3D Modeller. Shares her home with 9 cats!",
  },
  sudarshan: {
    fullName: "Sudarshan Kar",
    designation: "CEO",
    description: "Pro gamer with mad copywriting skills!",
  },
};

const mttNames = [
  "debanjana",
  "sayok",
  "debidas",
  "parijat",
  "souradip",
  "arka",
  "suchorita",
  "subhajit",
  "purnima",
  "sudarshan",
];

// Hello
export default function MeetTheTeam() {
  const [loading, setLoading] = useState(true);
  const [popupIsActive, setPopupIsActive] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [activeName, setActiveName] = useState("");
  const [barIsReady, setBarIsReady] = useState(false);
  const popupOpenAudio = useRef(null);
  const popupCloseAudio = useRef(null);

  useEffect(() => {
    // Function to change the theme color
    const changeThemeColor = () => {
      // Select the theme-color meta tag
      const themeColorMeta = document.querySelector('meta[name="theme-color"]');

      // Update the content attribute to the desired color
      if (themeColorMeta) {
        themeColorMeta.setAttribute("content", "#1b0d1c"); // Change to your desired color
      }
    };

    // Call the function to change theme color
    changeThemeColor();

    // Cleanup function
    return () => {
      // Reset theme color if necessary
      const themeColorMeta = document.querySelector('meta[name="theme-color"]');
      if (themeColorMeta) {
        themeColorMeta.setAttribute("content", "#00064a"); // Reset to default color
      }
    };
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };
    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  useEffect(() => {
    popupOpenAudio.current = new Audio("sounds/menu_open.mp3");
    popupCloseAudio.current = new Audio("sounds/menu_close.mp3");

    return () => {
      popupOpenAudio.current.pause();
      popupOpenAudio.current.currentTime = 0;
      popupCloseAudio.current.pause();
      popupCloseAudio.current.currentTime = 0;
    };
  }, []);

  function playPopupClose() {
    popupOpenAudio.current.pause();
    popupOpenAudio.current.currentTime = 0;
    popupCloseAudio.current.play();
  }

  function playPopupOpen() {
    popupCloseAudio.current.pause();
    popupCloseAudio.current.currentTime = 0;
    popupOpenAudio.current.play();
  }

  function handlePopupIsActive(value) {
    if (value) {
      setPopupIsActive(true);
    } else {
      setClosePopup(true);
      setTimeout(() => {
        setPopupIsActive(false);
        setClosePopup(false);
      }, 500);
    }
  }

  useKey("Escape", () => {
    handlePopupIsActive(false);
    playPopupClose();
  });

  return (
    <>
      <MTTLoadingScreen onLoading={loading} onBarIsReady={barIsReady} />
      {popupIsActive && (
        <MttPopup
          name={activeName}
          onPopupIsActive={handlePopupIsActive}
          onClosePopup={closePopup}
          playPopupClose={playPopupClose}
        />
      )}
      <div className={styles.mtt_container_dt}>
        <img
          src="/meet-the-team/mtt-bg.png"
          alt="meet-the-team"
          className={styles.mtt_bg_dt}
        />

        <Link to="/" className={styles.exit_container}>
          <DotLottiePlayer
            src="/lotties/mtt-exit.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.exit_lottie}
            loop
            autoplay
          ></DotLottiePlayer>
        </Link>

        <div className={styles.vending_machine_container}>
          <DotLottiePlayer
            src="/lotties/mtt-vending-machine.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.vending_machine_lottie}
            loop
            autoplay
          ></DotLottiePlayer>
          <div
            className={styles.debanjana_container}
            onClick={() => {
              setActiveName("debanjana");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
        </div>

        <div className={styles.bar_container}>
          <DotLottiePlayer
            src="/lotties/mtt-bar.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.bar_lottie}
            loop
            autoplay
            onEvent={(event) => {
              // Listen for player ready
              if (event === PlayerEvents.Ready) {
                setBarIsReady(true);
              }
            }}
          ></DotLottiePlayer>
          <div
            className={styles.sayok_container}
            onClick={() => {
              setActiveName("sayok");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.debidas_container}
            onClick={() => {
              setActiveName("debidas");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.parijat_container}
            onClick={() => {
              setActiveName("parijat");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.souradip_container}
            onClick={() => {
              setActiveName("souradip");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
        </div>

        <div className={styles.table_left_container}>
          <DotLottiePlayer
            src="/lotties/mtt-table-left.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.table_left_lottie}
            loop
            autoplay
          ></DotLottiePlayer>
          <div
            className={styles.arka_container}
            onClick={() => {
              setActiveName("arka");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.suchorita_container}
            onClick={() => {
              setActiveName("suchorita");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.subhajit_container}
            onClick={() => {
              setActiveName("subhajit");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
        </div>

        <div className={styles.table_right_container}>
          <DotLottiePlayer
            src="/lotties/mtt-table-right.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.table_right_lottie}
            loop
            autoplay
          ></DotLottiePlayer>
          <div
            className={styles.purnima_container}
            onClick={() => {
              setActiveName("purnima");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.sudarshan_container}
            onClick={() => {
              setActiveName("sudarshan");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
        </div>
      </div>
      <div className={styles.mtt_container_mb}>
        <img
          src="/meet-the-team/mtt-bg-mb.png"
          alt="meet-the-team"
          className={styles.mtt_bg_mb}
        />
        <Link to="/" className={styles.exit_container_mb}>
          <DotLottiePlayer
            src="/lotties/mtt-exit-mb.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.exit_lottie_mb}
            loop
            autoplay
          ></DotLottiePlayer>
        </Link>
        <div className={styles.bar_container_mb}>
          <DotLottiePlayer
            src="/lotties/mtt-bar-mb.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.bar_lottie_mb}
            loop
            autoplay
            onEvent={(event) => {
              // Listen for player ready
              if (event === PlayerEvents.Ready) {
                setBarIsReady(true);
              }
            }}
          ></DotLottiePlayer>
          <div
            className={styles.debidas_container_mb}
            onClick={() => {
              setActiveName("debidas");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.parijat_container_mb}
            onClick={() => {
              setActiveName("parijat");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.sayok_container_mb}
            onClick={() => {
              setActiveName("sayok");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.debanjana_container_mb}
            onClick={() => {
              setActiveName("debanjana");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.souradip_container_mb}
            onClick={() => {
              setActiveName("souradip");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
        </div>
        <div className={styles.table_right_container_mb}>
          <DotLottiePlayer
            src="/lotties/mtt-table-right-mb.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.table_right_lottie}
            loop
            autoplay
          ></DotLottiePlayer>
          <div
            className={styles.purnima_container_mb}
            onClick={() => {
              setActiveName("purnima");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.sudarshan_container_mb}
            onClick={() => {
              setActiveName("sudarshan");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
        </div>
        <div className={styles.table_left_container_mb}>
          <DotLottiePlayer
            src="/lotties/mtt-table-left-mb.lottie"
            background="transparent"
            speed="1"
            direction="1"
            mode="normal"
            className={styles.table_left_lottie}
            loop
            autoplay
          ></DotLottiePlayer>
          <div
            className={styles.arka_container_mb}
            onClick={() => {
              setActiveName("arka");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.suchorita_container_mb}
            onClick={() => {
              setActiveName("suchorita");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
          <div
            className={styles.subhajit_container_mb}
            onClick={() => {
              setActiveName("subhajit");
              handlePopupIsActive(true);
              playPopupOpen();
            }}
          ></div>
        </div>
      </div>
    </>
  );
}

function MttPopup({ name, onPopupIsActive, onClosePopup, playPopupClose }) {
  const [popupName, setPopupName] = useState(name);
  const [bgLoaded, setBgLoaded] = useState(false);
  const [popupLottieIsReady, setPopupLottieIsReady] = useState(false);
  const [isEntry, setIsEntry] = useState(true);
  const mttPopupRef = useRef(null);

  useEffect(() => {
    if (popupLottieIsReady) {
      mttPopupRef.current?.playSegments([42, 142], !isEntry);
    }
  }, [popupLottieIsReady, isEntry]);

  function handlePopupName(name) {
    setPopupName(name);
    setPopupLottieIsReady(false);
    setIsEntry(false);
  }

  function handlePopupIndex(direction = 1) {
    let ind = 0;

    ind = mttNames.indexOf(popupName);

    if (direction === 1) {
      if (ind === mttNames.length - 1) {
        return 0;
      } else {
        return ind + 1;
      }
    } else {
      if (ind === 0) {
        return mttNames.length - 1;
      } else {
        return ind - 1;
      }
    }
  }

  return (
    <div className={`${styles.mtt_popup_container}`}>
      <div
        className={`${styles.overlay} ${onClosePopup && styles.close_overlay}`}
        onClick={() => {
          onPopupIsActive(false);
          playPopupClose();
        }}
      ></div>
      <div
        className={`${styles.popup_lottie_container} ${
          onClosePopup && styles.close_popup
        } ${!bgLoaded && styles.invisible}`}
      >
        <img
          src="/meet-the-team/mtt-popup-bg.svg"
          alt=""
          className={`${styles.popup_bg}`}
          onLoad={() => {
            setBgLoaded(true);
          }}
        />
        <span
          className={styles.close}
          onClick={() => {
            onPopupIsActive(false);
            playPopupClose();
          }}
        >
          &times;
        </span>
        <DotLottiePlayer
          className={`${styles.popup_lottie} ${
            !popupLottieIsReady && styles.invisible
          }`}
          ref={mttPopupRef}
          src={`/lotties/mtt-${popupName}.lottie`}
          background="transparent"
          speed="1"
          direction="1"
          mode="normal"
          autoplay
          loop
          onEvent={(event) => {
            // console.log(mttPopupRef.current?.currentState)
            // Listen for player ready
            if (event === PlayerEvents.Ready) {
              setPopupLottieIsReady(true);
            }
          }}
        ></DotLottiePlayer>
        <img
          src="meet-the-team/mtt-left-arrow.svg"
          alt="Left Arrow"
          className={`${styles.popup_left_arrow} ${styles.popup_arrow} `}
          onClick={() => {
            if (popupLottieIsReady) {
              const nm = mttNames[handlePopupIndex(-1)];
              handlePopupName(nm);
            }
          }}
        />
        <img
          src="meet-the-team/mtt-right-arrow.svg"
          alt="Right Arrow"
          className={`${styles.popup_right_arrow} ${styles.popup_arrow}`}
          onClick={() => {
            if (popupLottieIsReady) {
              const nm = mttNames[handlePopupIndex(1)];
              handlePopupName(nm);
            }
          }}
        />
        <div
          className={`${styles.popup_name} ${
            !popupLottieIsReady && styles.invisible
          }`}
        >
          <span>{mttDetails[popupName].fullName}</span>
        </div>
        <h2
          className={`${styles.popup_designation} ${
            !popupLottieIsReady && styles.invisible
          }`}
        >
          {mttDetails[popupName].designation}
        </h2>
        <div
          className={`${styles.popup_description_container} ${
            !popupLottieIsReady && styles.invisible
          }`}
        >
          <h3 className={styles.popup_description}>
            {mttDetails[popupName].description}
          </h3>
        </div>
      </div>
    </div>
  );
}
