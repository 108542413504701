import styles from "./LoadingScreen.module.css";
import { motion } from "framer-motion";

const LETTER_DELAY = 0.05;
const BOX_FADE_DURATION = 0.25;

const Typewrite = ({ text, delayMobile = 0 }) => {
  return (
    <motion.p
      className={styles.text}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: BOX_FADE_DURATION,
        ease: "easeInOut",
      }}
    >
      <span className={styles.span_2}>
        {text.split("").map((l, i) => (
          <motion.span
            key={i}
            style={{
              position: "relative",
            }}
          >
            <motion.span
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                delay: i * LETTER_DELAY + delayMobile,
                duration: 0,
              }}
            >
              {l}
            </motion.span>
            <motion.span
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: [0, 1, 0],
              }}
              transition={{
                delay: i * LETTER_DELAY + delayMobile,
                times: [0, 0.1, 1],
                duration: BOX_FADE_DURATION,
                ease: "easeInOut",
              }}
              style={{
                position: "absolute",
                margin: "3px 0 3px 1px",
                backgroundColor: "#f4316d",
                width: "100%",
                height: "100%",
              }}
            />
          </motion.span>
        ))}
      </span>
    </motion.p>
  );
};

export default Typewrite;