import { useState, useEffect } from "react";
import styles from "./LoadingScreen.module.css";
import Typewrite from "./Typewrite";
import useScreenSize from "./useScreenSize";

const DELAY_MOBILE = 1.25;

export default function LoadingScreen({ onLoading, onRoofIsReady }) {
  const [welcomeClicked, setWelcomeClicked] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [screenSize] = useScreenSize();

  useEffect(() => {
    document.body.classList.add("no-scroll");
  }, []);

  useEffect(() => {
    const audio = new Audio("sounds/city.mp3");
    audio.loop = true;
    if (welcomeClicked) {
      audio.play();
    }
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [welcomeClicked]);

  return (
    <div
      className={`${styles.loading_container} ${
        welcomeClicked && styles.invisible
      }`}
    >
      <div className={styles.logo_container}>
        <div
          className={`${styles.img_container} ${logoLoaded && styles.loaded}`}
        >
          <img
            src="pics/loader.svg"
            alt="Aristocat Logo"
            className={`${styles.pulse_image} ${logoLoaded && styles.pulsing}`}
            onLoad={() => {
              setLogoLoaded(true);
            }}
          />
        </div>
      </div>

      {logoLoaded &&
        (screenSize === "desktop" ? (
          <Typewrite text="Where quality reigns, and creativity runs wild." />
        ) : (
          <div className={styles.typewrite_mobile}>
            <Typewrite text="Where quality reigns, and" />
            <Typewrite
              text="creativity runs wild."
              delayMobile={DELAY_MOBILE}
            />
          </div>
        ))}

      <div className={styles.welcome_container}>
        {!onLoading && logoLoaded && onRoofIsReady && (
          <div
            className={`${styles.welcome_button}`}
            onClick={() => {
              setWelcomeClicked(true);
              document.body.classList.remove("no-scroll");
            }}
          >
            Enter Aristocat Studio
          </div>
        )}
      </div>
    </div>
  );
}
